'use strict';

Gri.module({
  name: 'card-beta',
  ieVersion: null,
  $el: $('.card-beta'),
  container: '.card-beta',
  fn: function () {

    const $this = this.$el;
    let heights = [];
    setTimeout(function () {
      $this.each(function () {
        let thisHeight = $(this).outerHeight();
        heights.push(thisHeight);
      });
      let maxH = Math.max.apply(undefined, heights);
      $this.height(maxH);
    }, 1000);
  }
});
